import { useMemo } from 'react';
import { useLocale } from '@peloton/internationalize';
import LocaleHasNoFinancingPartnerError from '@ecomm/financing/errors/LocaleHasNoFinancingPartnerError';
import type { Props as FinancingProps } from '@ecomm/financing/FinancingProps';
import { bundleTypeToFinancedDevice } from '@ecomm/financing/FinancingProps';
import useGetFinancingPartner from '@ecomm/financing/hooks/useGetFinancingPartner';
import useGetSecondaryFinancingPartner from '@ecomm/financing/hooks/useGetSecondaryFinancingPartner';
import { roundByFinancingPartner } from '@ecomm/financing/models/monthlyPaymentWithApr';
import { Partners, PartnerDisplayNameMap } from '@ecomm/financing/models/Partners';
import { defaultFinancingTerm } from '@ecomm/financing/models/term';
import { useApr } from '@ecomm/pricing/helpers/useApr';
import useBundlePrice from '@ecomm/pricing/hooks/useBundlePrice';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import { getMembershipPriceForLocale } from '@ecomm/subscription-plans/utils';

/**
 * Next compatible / non-redux rewrite of useFinancing
 */
const useFinancingNext = (bundleType: BundleType): FinancingProps => {
  const devicePrice = useBundlePrice(bundleType);
  const locale = useLocale();

  const partner = useGetFinancingPartner();
  const secondaryPartner = useGetSecondaryFinancingPartner();
  if (partner === Partners.None) {
    throw new LocaleHasNoFinancingPartnerError(
      'Cannot useFinancing if no financing partner',
    );
  }

  /** @TODO clean up zipThirtySixMonthFinancing flag in downstream references. It's always true. */
  const term = defaultFinancingTerm({ partner, bundleType }, true);
  const termNumber = Number(term);
  const apr = useApr(locale, bundleType, termNumber);

  return useMemo(() => {
    const financedDevice = bundleTypeToFinancedDevice(bundleType);
    const membershipPrice = getMembershipPriceForLocale(bundleType, locale);

    const monthly = roundByFinancingPartner(devicePrice, termNumber, apr, partner);

    return {
      bundleType,
      financedDevice,
      financingPartner: partner,
      secondaryFinancingPartner: secondaryPartner,
      financingPartnerDisplayName: PartnerDisplayNameMap[partner],
      membershipPrice,
      total: devicePrice,
      totalBeforeShipping: devicePrice,
      monthly,
      term,
      apr,
      shippingEstimate: 0,
    };
  }, [partner, secondaryPartner, devicePrice, bundleType, locale, term, termNumber, apr]);
};

export default useFinancingNext;
