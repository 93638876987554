import type { Locale } from '@peloton/internationalize';
import { GUIDE_MEMBERSHIP_PRICING } from '@peloton/internationalize/consolidatedAppConfigs/models';
import { localeToMembershipPrice } from '@peloton/internationalize/consolidatedAppConfigs/utils';
import { isGuideBundleTypeMatch } from '@ecomm/shop/models/Bundle';
import type { BundleType } from '@ecomm/shop/models/BundleType';

export const getMembershipPriceForLocale = (device: BundleType, locale: Locale) => {
  if (isGuideBundleTypeMatch(device)) {
    return GUIDE_MEMBERSHIP_PRICING[locale];
  }

  return localeToMembershipPrice(locale);
};
